import React, { useEffect, useMemo, useState } from "react";
import {
  Flex,
  Button,
  Text,
  useColorModeValue,
  Box,
  Grid,
  GridItem,
  useToast,
} from "@chakra-ui/react";
import useAxios from "axios-hooks";
import { isEmpty } from "lodash";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { ROOT_API } from "utils/constant";
import { API_ROUTES } from "utils/constant";
import { FileImageValid } from "utils/constant";
import { getKeyByValue } from "utils/helpers";
import { checkDataChanged } from "utils/helpers";
import FileSelect from "components/Form/FileSelectController";
import { uploadImage, useDeleteBannerMutation, useQueryGetBanner, useUpdateBannerMutation, useUploadBannerMutation } from "services/banner";
import { POST_TYPE } from "constants/common";
import Loading from "components/Layout/Loading";
// import FileSelect from "components/Form/FileSelectController";

const Position = {
  Left: "LEFT",
  Right: "RIGHT",
  Top1: "TOP_1",
  Top2: "TOP_2",
};

const PositionKey = {
  [Position.Left]: "bannerLeftFile",
  [Position.Right]: "bannerRightFile",
  [Position.Top1]: "bannerTop1File",
  [Position.Top2]: "bannerTop2File",
};

const BannerAds = () => {
  const toast = useToast();
  const textColor = useColorModeValue("gray.700", "white");
  const [fileSelected, setFileSelected] = useState(null);
  const [error, setError] = useState();
  // const isDisableSaveBtn = useMemo(() => checkDataChanged(fileSelected), [
  //   fileSelected,
  // ]);
  const { data: banner, isLoading, refetch } = useQueryGetBanner({ type: POST_TYPE.BANNER })
  const deleteBannerMutation = useDeleteBannerMutation()
  const uploadBannerMutation = useUploadBannerMutation()
  const updateBannerMutation = useUpdateBannerMutation()
  const handleSuccess = (title) => {
    toast({
      title: title,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };
  const handleError = (error, title) => {
    toast({
      title:
        error || title,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };
  useEffect(() => {
    if (!isEmpty(banner?.data)) {
      setFileSelected({ filePreview: `${process.env.REACT_APP_API_HOST}${banner?.data[0]?.thumbnailUrl}` });
    }
  }, [banner?.data]);

  const handleFileSelect = (id, e) => {
    if (e?.target?.files?.[0]) {
      const fileSelected = e.target.files[0];
      const filePreview = URL.createObjectURL(fileSelected);
      const extensionFile = fileSelected?.name
        ?.split(".")
        ?.pop()
        ?.toLowerCase();

      if ([...FileImageValid, "gif"].includes(extensionFile)) {
        setFileSelected({ file: fileSelected, filePreview, id });
        return;
      }

      setFileSelected();
      handleErrorFile(
        "File reload formats are supported only .png, .jpeg, .jpg"
      );
    }
  };

  const handleErrorFile = (value) => {
    setError(value);
  };
  const handleRemoveFile = (id) => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa banner này không?');
    if (!confirmDelete) {
      return;
    }

    deleteBannerMutation.mutate(
      { id },
      {
        onSuccess: () => {
          setFileSelected(null);
          handleSuccess('Xoá banner thành công')
          refetch?.();
        },
        onError: (err) => {
          handleError(err, 'Xoá banner thất bại')
          refetch?.();
        },
      }
    );
  };
  const onUploadPhoto = async () => {
    const formData = new FormData();
    let pathFiles = [];

    if (!isEmpty(fileSelected)) {
      formData.append('imageFile', fileSelected?.file);

      const response = await uploadImage(formData);

      pathFiles = response?.data;
    }
    return pathFiles;
  };
  const onSubmit = async () => {
    const photosPath = await onUploadPhoto();
    if (photosPath) {
      const data = {
        type: POST_TYPE.BANNER,
        isShow: true,
        thumbnailUrl: photosPath?.imageUrl
      }
      if (!fileSelected?.id) {
        uploadBannerMutation.mutate(
          data,
          {
            onSuccess: () => {
              setFileSelected(null);
              handleSuccess('Tạo banner thành công')
              refetch?.();
            },
            onError: (err) => {
              handleError(err, 'Tạo banner thất bại')
              refetch?.();
            },
          }
        );
      } else {
        updateBannerMutation.mutate(
          { ...data, id: fileSelected?.id },
          {
            onSuccess: () => {
              setFileSelected(null);
              handleSuccess('Cập nhật banner thành công')
              refetch?.();
            },
            onError: (err) => {
              handleError(err, 'Cập nhật banner thất bại')
              refetch?.();
            },
          }
        );
      }
    }
  };
  return (
    <>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }} >
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px" bg="#fff" minHeight={"250px"}>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Banner
            </Text>
          </CardHeader>
          {isLoading ? (
            <Loading />
          ) : (
            <CardBody pb={4}>
              <form>
                <Grid gap={4}>
                  <GridItem>
                    <FileSelect
                      label="Banner"
                      id={banner?.data[0]?._id}
                      filePreview={fileSelected && fileSelected?.filePreview}
                      error={error}
                      deleteIcon={!fileSelected?.file}
                      handleFileSelect={handleFileSelect}
                      handleErrorFile={handleErrorFile}
                      handleRemoveFile={handleRemoveFile}
                    />
                  </GridItem>
                </Grid>
              </form>
              <Flex mt={3} columnGap={3} justifyContent={"end"}>
                <Box>
                  <Button
                    colorScheme="blue"
                    onClick={onSubmit}
                  >
                    Save
                  </Button>
                </Box>
              </Flex>
            </CardBody>
          )}
        </Card>
      </Flex >
    </>
  );
};

export default BannerAds;
