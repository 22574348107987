// Chakra imports
import {
    Flex,
    Table,
    Button,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useDisclosure,
    Input,
    InputRightElement,
    InputGroup,
    useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useRef, useState } from "react";
import { TablePagination } from "@trendmicro/react-paginations";
import { initialFilter } from "utils/constant";
import { SearchIcon } from "@chakra-ui/icons";
import Loading from "components/Layout/Loading";
import { useQueryGetListLeagues } from "services/category";
import { CookieStorage } from "utils/cookie-storage";
import LeagueTableRow from "./components/LeagueTableRow";

function League() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const isLoggedIn = CookieStorage.isAuthenticated();
    const [filter, setFilter] = useState(initialFilter);
    const toast = useToast();
    const [searchValue, setSearchValue] = useState("");
    const {
        isOpen: isOpenCreate,
        onOpen: onOpenCreate,
        onClose: onCloseCreate,
    } = useDisclosure();
    const refSearchButton = useRef(null);
    const { data: leagues, isLoading, refetch } = useQueryGetListLeagues({ ...filter }, { enabled: isLoggedIn })

    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Card overflowX={{ sm: "scroll", xl: "hidden" }} bgColor={'#fff'} pb="0px">
                <CardHeader p="6px 0px 22px 0px">
                    <Text fontSize="xl" color={textColor} fontWeight="bold">
                        Danh sách giải đấu
                    </Text>
                </CardHeader>
                <CardBody pb={4}>
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <Flex columnGap={3} mb={3}>
                                <InputGroup>
                                    <InputRightElement pointerEvents="none">
                                        <SearchIcon color="gray.300" />
                                    </InputRightElement>
                                    <Input
                                        type="text"
                                        variant="outline"
                                        placeholder="Tìm kiếm với tên, mã số"
                                        value={searchValue}
                                        onChange={(event) => setSearchValue(event.target.value)}
                                        onKeyUp={(event) => {
                                            if (event.key === "Enter" && event?.target?.value) {
                                                const searchBtn = refSearchButton?.current;
                                                if (searchBtn !== null) {
                                                    searchBtn.click();
                                                }
                                            }
                                        }}
                                    />
                                </InputGroup>

                                <Button
                                    ref={refSearchButton}
                                    disabled={!searchValue}
                                    colorScheme="blue"
                                    onClick={() => {
                                        setFilter({
                                            ...filter,
                                            searchKeyword: searchValue,
                                            pageIndex: 0,
                                        });
                                    }}
                                >
                                    Tìm kiếm
                                </Button>
                                <Button
                                    onClick={() => {
                                        setSearchValue("");
                                        setFilter({
                                            ...filter,
                                            searchKeyword: "",
                                            pageIndex: 0,
                                        });
                                    }}
                                >
                                    Đặt lại
                                </Button>
                            </Flex>
                            <Table variant="simple" color={textColor}>
                                <Thead>
                                    <Tr my=".8rem" pl="0px" color="gray.400">
                                        <Th borderColor={borderColor} color="gray.400">
                                            Mã giải đấu
                                        </Th>
                                        <Th borderColor={borderColor} color="gray.400">
                                            Logo
                                        </Th>
                                        <Th borderColor={borderColor} color="gray.400">
                                            Giải đấu
                                        </Th>
                                        <Th borderColor={borderColor} color="gray.400">
                                            Trạng thái
                                        </Th>
                                        <Th borderColor={borderColor} color="gray.400">
                                            Top giải đấu
                                        </Th>
                                        {/* <Th borderColor={borderColor} color="gray.400">
                                            Action
                                        </Th> */}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {leagues?.data?.length
                                        ? leagues?.data?.map((row, index, arr) => {
                                            return (
                                                <LeagueTableRow
                                                    row={row}
                                                    isLast={index === arr.length - 1 ? true : false}
                                                    key={index}
                                                    fetchData={refetch}
                                                />
                                            );
                                        })
                                        : ""}
                                </Tbody>
                            </Table>
                            <Flex justifyContent={"flex-end"}>
                                <TablePagination
                                    type="full"
                                    page={leagues?.pagination?.page}
                                    pageLength={leagues?.pagination?.pageSize}
                                    totalRecords={leagues?.pagination?.count}
                                    onPageChange={({ page, pageLength }) => {
                                        setFilter({
                                            ...filter,
                                            pageSize: pageLength,
                                            pageIndex: page - 1,
                                        });
                                    }}
                                    prevPageRenderer={() => <i className="fa fa-angle-left" />}
                                    nextPageRenderer={() => <i className="fa fa-angle-right" />}
                                />
                            </Flex>
                        </>
                    )}
                </CardBody>
            </Card>
        </Flex>
    );
}

export default League;
