import { useMutation, useQuery } from '@tanstack/react-query';
import { ROOT_API } from 'constants/common';
import { request } from 'utils/request';

export const getPost = (params) => request.get(ROOT_API + '/api/v1/posts', { params });

export const getDetailPost = id => request.get(`/api/v1/posts/${id}`);
export const showPost = id => request.post(`/api/v1/posts/change-show/${id}`);
export const createPost = (data) => request.post('/api/v1/posts/create', data);
// request.post(`/api/v1/posts/create`, data);
export const updatePost = (data) => request.post('/api/v1/posts/update', data);
export const changeStatusPost = params => request.post(`/api/v1/posts/change-show/${params}`);
export const deletePost = data => request.post('/api/v1/posts/delete', data);
export const uploadPhotoPost = (data, config) =>
    request.post('/api/v1/posts/upload-image', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        ...config,
    });
export const uploadVideoPost = (data, config) =>
    request.post('/api/v1/posts/upload-video', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        ...config,
    });
//mutation
export const useShowPostMutation = () => useMutation({ mutationFn: id => showPost(id) });
export const useDeletePostMutation = () => useMutation({ mutationFn: deletePost });
export const useCreatePostMutation = () => useMutation({ mutationFn: createPost });
export const useUpdatePostMutation = () => useMutation({ mutationFn: updatePost });
export const useChangeStatusPostMutation = () => useMutation({ mutationFn: changeStatusPost });
// Query
export const useQueryGetPost = (params = {}, options = {}) => useQuery({ queryKey: ['GET_POST', params], queryFn: () => getPost(params), ...options });

export const useQueryGetDetailPost = (id, options = {}) =>
    useQuery({ queryKey: ['GET_POST_DETAIL', id], queryFn: () => getDetailPost(id), ...options });
