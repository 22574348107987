import React, { useEffect, useState } from 'react';
import {
    Button,
    FormControl,
    FormLabel,
    Box,
    Text,
    Grid,
    GridItem,
    Image,
    IconButton,
    Input,
    Flex,
    Card,
    CardHeader,
    Switch,
    useColorModeValue,
    CardBody,
    SimpleGrid,
    Heading,
    CardFooter,
    useToast,
} from '@chakra-ui/react';
import { useParams, useHistory } from 'react-router-dom';
import InputController from 'components/Form/InputController';
import { ModalType, ResultOptions } from 'constants/common';
import SelectController from 'components/Form/SelectController';
import { BsUpload, BsXCircle } from 'react-icons/bs';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';
import { useQueryGetDetailTips, useSubmitResultMutation } from 'services/tips';
import { useForm } from 'react-hook-form';
import NumericInputController from 'components/Form/NumericInputController';
import { isEmpty } from 'lodash';
const TipDetail = () => {
    const textColor = useColorModeValue("gray.700", "white");
    const params = useParams();
    const cancelRef = React.useRef();
    const { id } = params || {};
    const history = useHistory();
    const toast = useToast();
    const { data: detailTips } = useQueryGetDetailTips(id)
    const submitResultMutation = useSubmitResultMutation()
    const [isOpen, setIsOpen] = useState(false)
    const { control, handleSubmit, reset, setValue } = useForm({
        defaultValues: {
            result: undefined,
            tip: '',
        },
    });
    console.log(detailTips);
    const handleSuccess = () => {
        toast({
            title: `Update result tips Successfully`,
            status: "success",
            duration: 9000,
            isClosable: true,
        });
    };

    const handleError = (error) => {
        toast({
            title:
                error ||
                `Update result tips fail`,
            status: "error",
            duration: 9000,
            isClosable: true,
        });
    };
    const onSubmit = async values => {
        submitResultMutation.mutate({
            id,
            result: values?.result?.value,
            tip: values?.tip
        }, {
            onSuccess: () => {
                handleSuccess();
                history.push('/admin/tips');
            },
            onError: error => {
                // handleError(error)
                reset();
            }
        })
        // if (isEmpty(files)) {
        //     setError({ thumbnailFile: 'Tải lên ít nhất 1 hình ảnh' });
        //     return;
        // }

        // if (isEmpty(files)) {
        //     setError({ thumbnailFile: 'Tải lên ít nhất 1 hình ảnh' });
        //     return;
        // }

        // const photosPath = await onUploadPhoto();

        // let videoPath = '';
        // if (videoSrc && inforVideo) {
        //     videoPath = await onUploadVideo();
        // } else if (postEdit?.videoUrl) {
        //     videoPath = [postEdit.videoUrl];
        // }

        // const dataSubmit = {
        //     ...values,
        //     thumbnailUrl: photosPath[0],
        //     videoUrl: videoPath[0],
        //     type: values.type.value,
        // };

        // if (!!postEdit?._id) {
        //     updatePostMutation.mutate(
        //         { ...dataSubmit, id: postEdit?._id },
        //         {
        //             onSuccess: () => {
        //                 refetch();
        //                 toast.showMessageSuccess('Cập nhập bài viết thành công');
        //                 onClose(ModalType.Add);
        //             },
        //             onError: () => {
        //                 toast.showMessageError('Cập nhập bài viết thất bại');
        //             },
        //         }
        //     );
        //     return;
        // }

        // createPostMutation.mutate(dataSubmit, {
        //     onSuccess: () => {
        //         handleSuccess();
        //     },
        //     onError: () => {
        //         toast.showMessageError('Tạo bài viết thất bại');
        //     },
        // });
    };
    return (
        // <>
        //     <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        //         <Card bg={'#fff'} overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        //             <CardHeader p="6px 0px 22px 0px">
        //                 <Text fontSize="xl" color={textColor} fontWeight="bold">
        //                     {"Chi tiết nhận định"}
        //                 </Text>
        //             </CardHeader>
        //             <CardBody pb={4}>
        //                 <Card mb={4} border={'1px'} borderColor={'#C0C0C0'}>
        //                     <CardHeader borderBottom={'1px'} borderColor={'#C0C0C0'}>
        //                         <Heading size='md'>
        //                             {detailTips?.data?.match?.home_name} vs {detailTips?.data?.match?.away_name}
        //                         </Heading>
        //                     </CardHeader>
        //                     <CardBody >
        //                         <Text mb={'8px'}>
        //                             {detailTips?.data?.content}
        //                         </Text>
        //                         <Flex alignItems="center" justifyContent="flex-start"
        //                             gap={2}
        //                         >
        //                             <Heading size={'sm'}>
        //                                 {detailTips?.data?.name} :
        //                             </Heading>
        //                             <Flex width={'fit-content'}
        //                                 px={2} py={1}
        //                                 borderRadius={'6px'}
        //                                 alignItems="flex-start" justifyContent="flex-start" gap={10} border={'1px'}
        //                                 borderColor={'#C0C0C0'}
        //                             >
        //                                 <Text>
        //                                     {detailTips?.data?.value}
        //                                 </Text>
        //                                 <Text>
        //                                     {detailTips?.data?.odd}
        //                                 </Text>
        //                             </Flex>
        //                         </Flex>
        //                     </CardBody>
        //                     {!isOpen &&
        //                         <CardFooter
        //                             borderTop={'1px'} borderColor={'#C0C0C0'}
        //                             onClick={() => setIsOpen(true)}
        //                         >
        //                             <Button>Đánh giá</Button>
        //                         </CardFooter>
        //                     }
        //                 </Card>
        //                 {isOpen &&
        //                     <>
        //                         <form>

        //                             <SelectController
        //                                 control={control}
        //                                 name="result"
        //                                 label="Kết quả"
        //                                 isClearable
        //                                 options={ResultOptions}
        //                             // onChange={handleTypeSelect}
        //                             />
        //                             <NumericInputController style={{ marginBottom: '4px', marginTop: '4px' }} control={control} name="tip" label="Tip" />
        //                             <InputController style={{ marginBottom: '4px', marginTop: '4px' }} control={control} name="description" label="Mô tả" />
        //                         </form>
        //                         <Flex pt={4} alignItems="flex-end" justifyContent="flex-end">
        //                             <Button
        //                                 ref={cancelRef}
        //                                 onClick={() => {
        //                                     setIsOpen(false)
        //                                 }}
        //                             >
        //                                 Huỷ
        //                             </Button>
        //                             <Button colorScheme="blue" ml={3} onClick={handleSubmit(onSubmit)}>
        //                                 Cập nhật
        //                             </Button>
        //                         </Flex>
        //                     </>
        //                 }
        //             </CardBody>
        //         </Card>
        //     </Flex>
        // </>
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Card bg={'#fff'} overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">

                <CardHeader>
                    <Text fontSize="xl" color={textColor} fontWeight="bold">
                        {"Chi tiết nhận định"}
                    </Text>
                </CardHeader>
                {/* <Flex direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    marginX={'auto'}
                    width="60%"> */}
                <CardBody pb={4}>
                    <Card width={'60%'} mx={'auto'} mb={4} border={'1px'} borderColor={'#C0C0C0'}>
                        {/* <CardHeader>
                            <Flex direction="column"
                                gap={'4px'}
                                justifyContent="space-between"
                                alignItems="center">
                                <Heading size='md'>
                                    {detailTips?.data?.match?.home_name} vs {detailTips?.data?.match?.away_name}
                                </Heading>
                                <Heading size={'md'}>
                                    0 - 2
                                </Heading>
                                <Text fontSize={'10px'} textColor={'#C0C0C0'}>
                                    HT: | FT:
                                </Text>
                            </Flex>
                        </CardHeader> */}
                        <CardBody >
                            <Flex direction="column"
                                gap={'4px'}
                                justifyContent="space-between"
                                alignItems="center">
                                <Heading size='md'>
                                    {detailTips?.data?.match?.home_name} vs {detailTips?.data?.match?.away_name}
                                </Heading>
                                {isEmpty(detailTips?.data?.match?.score) ?
                                    <Text fontSize={'12px'} textColor={'#999999'}>
                                        Kết quả trận đấu chưa được cập nhập
                                    </Text>
                                    :
                                    <>
                                        <Heading size={'md'}>
                                            {detailTips?.data?.match?.homeGoals} - {detailTips?.data?.match?.awayGoals}
                                        </Heading>
                                        <Text fontSize={'10px'} textColor={'#999999'}>
                                            HT: {detailTips?.data?.match?.score?.halftime?.home}-{detailTips?.data?.match?.score?.halftime?.away} | FT: {detailTips?.data?.match?.score?.extratime?.home ? `${detailTips?.data?.match?.score?.extratime?.home}-${detailTips?.data?.match?.score?.extratime.away}` : `${detailTips?.data?.match?.score?.fulltime?.home}-${detailTips?.data?.match?.score?.fulltime?.away}`}
                                        </Text>
                                        {detailTips?.data?.match?.score?.penalty?.home &&
                                            <Text fontSize={'10px'} textColor={'#999999'}>
                                                Penalties: {detailTips?.data?.match?.score?.penalty?.home}-{detailTips?.data?.match?.score?.penalty?.away}
                                            </Text>
                                        }
                                    </>
                                }
                            </Flex>
                            <Flex direction="column"
                                justifyContent="space-between" marginTop={'8px'}
                                alignItems="center" paddingY={'10px'} paddingX={'20px'} border={'1px'} borderColor={'#C0C0C0'}>
                                <Heading size={'lg'}>
                                    {detailTips?.data?.content}
                                </Heading>
                                <Flex alignItems="center" justifyContent="flex-start"
                                    gap={2}
                                >
                                    <Heading size={'sm'}>
                                        {detailTips?.data?.name} :
                                    </Heading>
                                    <Flex width={'fit-content'}
                                        px={2} py={1}
                                        borderRadius={'6px'}
                                        alignItems="flex-start" justifyContent="flex-start" gap={10} border={'1px'}
                                        borderColor={'#C0C0C0'}
                                    >
                                        <Text>
                                            {detailTips?.data?.value}
                                        </Text>
                                        <Text>
                                            {detailTips?.data?.odd}
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </CardBody>
                        {!isOpen &&
                            <CardFooter
                                borderTop={'1px'} borderColor={'#C0C0C0'}
                                onClick={() => setIsOpen(true)}
                            >
                                <Flex direction="column"
                                    width={'100%'}
                                    justifyContent="space-between"
                                    alignItems="center">
                                    <Button width={'100%'}>Đánh giá</Button>
                                </Flex>
                            </CardFooter>
                        }
                    </Card>
                    {isOpen &&
                        <>
                            <form>

                                <SelectController
                                    control={control}
                                    name="result"
                                    label="Kết quả"
                                    isClearable
                                    options={ResultOptions}
                                // onChange={handleTypeSelect}
                                />
                                <NumericInputController style={{ marginBottom: '4px', marginTop: '4px' }} control={control} name="tip" label="Tip" />
                                {/* <InputController style={{ marginBottom: '4px', marginTop: '4px' }} control={control} name="description" label="Mô tả" /> */}
                            </form>
                            <Flex pt={4} alignItems="flex-end" justifyContent="flex-end">
                                <Button
                                    ref={cancelRef}
                                    onClick={() => {
                                        setIsOpen(false)
                                    }}
                                >
                                    Huỷ
                                </Button>
                                <Button colorScheme="blue" ml={3} onClick={handleSubmit(onSubmit)}>
                                    Cập nhật
                                </Button>
                            </Flex>
                        </>
                    }
                </CardBody>
                {/* </Flex> */}
            </Card>
        </Flex >
    );
};

export default TipDetail;