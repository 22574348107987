import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputController from 'components/Form/InputController';
import { BlockReviewerFormValidate } from 'utils/validation';
import { ModalType, STATUS_BLOCK, StatusBlockOptions } from 'constants/common';
import { useBlockReviewerMutation } from 'services/size';
import { toast } from 'components/Toast';
import SelectController from 'components/Form/SelectController';

const BlockReviewerModal = ({ isOpen, reviewerDetail, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const blockReviewerMutation = useBlockReviewerMutation();
  const [showInputDate, setShowInputDate] = useState(false);

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(BlockReviewerFormValidate),
    defaultValues: {
      status: null,
      date: ''
    },
  });

  useEffect(() => {
    if (reviewerDetail) {
      reset({
        status: {
          label: reviewerDetail?.status === 'Temporary' ? 'Khoá tạm thời' : reviewerDetail?.status === 'Normal' ? 'Bình thường' : 'Khoá vĩnh viễn',
          value: reviewerDetail?.status
        }
      });
    }
  }, [reviewerDetail]);

  const handleSuccess = () => {
    toast.showMessageSuccess('Cập nhập trạng thái thành công');
    refetch?.();
    onClose(ModalType.ChangeStatus);
  };

  const handleError = error => {
    toast.showMessageError(
      error || `Cập nhập trạng thái`
    );
  };

  const onSubmit = values => {
    blockReviewerMutation.mutate(
      { ...values, reviewerID: reviewerDetail?._id, status: values.status.value },
      { onSuccess: () => handleSuccess(), onError: error => handleError(error) }
    );
  };

  const handleTypeBlockSelect = event => {
    if (!event) return;
    event.value === STATUS_BLOCK.TEMPORARY ? setShowInputDate(true) : setShowInputDate(false);
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.ChangeStatus);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader textTransform="uppercase">
            Cập nhập trạng thái
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <form>
              {showInputDate && <InputController isRequired type="datetime-local" control={control} name="date" label="Tên kích thước" />}
              <SelectController
                styleContainer={{ pt: '4' }}
                control={control}
                isRequired
                name="status"
                label="Loại"
                isClearable
                options={StatusBlockOptions}
                onChange={handleTypeBlockSelect}
              />
            </form>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.ChangeStatus);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={blockReviewerMutation.isPending} onClick={handleSubmit(onSubmit)}>
              {'Cập nhập'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default BlockReviewerModal;
