// Chakra imports
import {
    Flex,
    Table,
    Button,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useDisclosure,
    Input,
    InputRightElement,
    InputGroup,
    useToast,
    Stack,
    Td,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { Fragment, useRef, useState } from "react";
import { TablePagination } from "@trendmicro/react-paginations";
import { initialFilter } from "utils/constant";
import { SearchIcon } from "@chakra-ui/icons";
import Loading from "components/Layout/Loading";
import { useQueryGetListMatches } from "services/category";
import { CookieStorage } from "utils/cookie-storage";
import WeekFilter from "../WeekFilter/WeekFilter";
import MatchRow from "./components/MatchRow";

function Matches() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const bgColor = useColorModeValue("blue.200", "white");
    const isLoggedIn = CookieStorage.isAuthenticated();
    const [filter, setFilter] = useState(initialFilter);
    const [searchValue, setSearchValue] = useState("");
    const refSearchButton = useRef(null);
    const onFilterMatch = (dataFilter) => {
        setFilter({
            ...filter,
            pageIndex: 0,
            date: dataFilter,
        });
    };
    const { data: matches, isLoading } = useQueryGetListMatches({ ...filter }, { enabled: isLoggedIn })
    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Card overflowX={{ sm: "scroll", xl: "hidden" }} bgColor={'#fff'} pb="0px">
                <CardHeader p="6px 0px 22px 0px">
                    <Text fontSize="xl" color={textColor} fontWeight="bold">
                        Danh sách trận đấu
                    </Text>
                </CardHeader>
                <WeekFilter onFilterMatch={onFilterMatch} filterProps={filter} />
                <CardBody pb={4}>
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <Flex columnGap={3} mb={3}>
                                <InputGroup>
                                    <InputRightElement pointerEvents="none">
                                        <SearchIcon color="gray.300" />
                                    </InputRightElement>
                                    <Input
                                        type="text"
                                        variant="outline"
                                        placeholder="Tìm kiếm với tên, mã số"
                                        value={searchValue}
                                        onChange={(event) => setSearchValue(event.target.value)}
                                        onKeyUp={(event) => {
                                            if (event.key === "Enter" && event?.target?.value) {
                                                const searchBtn = refSearchButton?.current;
                                                if (searchBtn !== null) {
                                                    searchBtn.click();
                                                }
                                            }
                                        }}
                                    />
                                </InputGroup>

                                <Button
                                    ref={refSearchButton}
                                    disabled={!searchValue}
                                    colorScheme="blue"
                                    onClick={() => {
                                        setFilter({
                                            ...filter,
                                            searchKeyword: searchValue,
                                            pageIndex: 0,
                                        });
                                    }}
                                >
                                    Tìm kiếm
                                </Button>
                                <Button
                                    onClick={() => {
                                        setSearchValue("");
                                        setFilter({
                                            ...filter,
                                            searchKeyword: "",
                                            pageIndex: 0,
                                        });
                                    }}
                                >
                                    Đặt lại
                                </Button>
                            </Flex>
                            <Stack overflow={"auto"}>
                                <Table variant="simple" color={textColor}>
                                    <Thead bg={bgColor} hidden>
                                        <Tr my=".8rem" pl="0px" color={textColor}>
                                            <Th borderColor={borderColor} color={textColor}>
                                                Giải đấu
                                            </Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {matches?.matches?.length
                                            ? matches.matches.map((row, index, arr) => {
                                                return (
                                                    <Fragment key={index}>
                                                        <Tr>
                                                            <Td
                                                                borderColor={borderColor}
                                                                borderBottom={
                                                                    index === arr.length - 1 ? "none" : null
                                                                }
                                                                // colSpan={6}
                                                                bg={borderColor}
                                                            >
                                                                <Text
                                                                    fontSize="md"
                                                                    color={textColor}
                                                                    fontWeight="bold"
                                                                >
                                                                    {row.league_name}
                                                                </Text>
                                                            </Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td px={0} py={0}>
                                                                <Table>
                                                                    <Thead>
                                                                        <Tr
                                                                            py="1rem"
                                                                            pl="0px"
                                                                            color={textColor}
                                                                            bg={bgColor}
                                                                        >
                                                                            <Th
                                                                                borderColor={borderColor}
                                                                                color={textColor}
                                                                            >
                                                                                Mã trận đấu
                                                                            </Th>
                                                                            <Th
                                                                                borderColor={borderColor}
                                                                                color={textColor}
                                                                            >
                                                                                Đội nhà / Đội khách
                                                                            </Th>
                                                                            <Th
                                                                                borderColor={borderColor}
                                                                                color={textColor}
                                                                            >
                                                                                Thời gian
                                                                            </Th>
                                                                            <Th
                                                                                borderColor={borderColor}
                                                                                color={textColor}
                                                                            >
                                                                                Trạng thái
                                                                            </Th>
                                                                            <Th
                                                                                borderColor={borderColor}
                                                                                color={textColor}
                                                                            >
                                                                                Kết quả
                                                                            </Th>
                                                                            <Th
                                                                                borderColor={borderColor}
                                                                                color={textColor}
                                                                            >
                                                                                Trực tiếp
                                                                            </Th>
                                                                            {/* <Th
                                                                                borderColor={borderColor}
                                                                                color={textColor}
                                                                            >
                                                                                Action
                                                                            </Th> */}
                                                                        </Tr>
                                                                    </Thead>
                                                                    {/* {row?.matches?.length */}
                                                                    {/* // ? row.matches.map( */}
                                                                    {/* // (row_child, _index, _arr) => ( */}
                                                                    <Tbody>
                                                                        <MatchRow
                                                                            data={row}
                                                                            isLast={
                                                                                index === arr.length - 1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                        />
                                                                    </Tbody>
                                                                </Table>
                                                            </Td>
                                                        </Tr>
                                                    </Fragment>
                                                );
                                            })
                                            : ""}
                                    </Tbody>
                                </Table>
                            </Stack>
                            <Flex justifyContent={"flex-end"}>
                                <TablePagination
                                    type="full"
                                    page={matches?.pagination?.page}
                                    pageLength={matches?.pagination?.pageSize}
                                    totalRecords={matches?.pagination?.count}
                                    onPageChange={({ page, pageLength }) => {
                                        setFilter({
                                            ...filter,
                                            pageSize: pageLength,
                                            pageIndex: page - 1,
                                        });
                                    }}
                                    prevPageRenderer={() => <i className="fa fa-angle-left" />}
                                    nextPageRenderer={() => <i className="fa fa-angle-right" />}
                                />
                            </Flex>
                        </>
                    )}
                </CardBody>
            </Card>
        </Flex>
    );
}

export default Matches;
