import { useMutation, useQuery } from '@tanstack/react-query';
import { ROOT_API } from 'constants/common';
import { request } from 'utils/request';

export const uploadBanner = data => request.post(ROOT_API + '/api/v1/posts/create', data);
export const updateBanner = data => request.post(ROOT_API + '/api/v1/posts/update', data);
export const deleteBanner = data => request.post(ROOT_API + '/api/v1/posts/delete', data);
export const uploadImage = (data, config) =>
    request.post(ROOT_API + '/api/v1/posts/upload-image', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        ...config,
    });
export const changeStatus = params => request.post(ROOT_API + `/api/v1/posts/change-show/${params}`);
export const getBanner = params => request.get(ROOT_API + '/api/v1/posts/', { params });


export const useQueryGetBanner = (params = {}, options = {}) => useQuery({ queryKey: ['GET_BANNER', params], queryFn: () => getBanner(params), ...options });
// Mutation
export const useUploadBannerMutation = () => useMutation({ mutationFn: uploadBanner });
export const useUpdateBannerMutation = () => useMutation({ mutationFn: updateBanner });
export const useDeleteBannerMutation = () => useMutation({ mutationFn: deleteBanner });
export const useChangeStatusMutation = () => useMutation({ mutationFn: changeStatus });

