import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  FormLabel,
  FormControl,
  useToast,
  Flex,
  Text,
  Box,
  Image,
  useColorModeValue,
  Switch,
  Grid,
  GridItem,
  IconButton,
} from "@chakra-ui/react";
import { nanoid } from 'nanoid';
import { BsUpload, BsXCircle } from 'react-icons/bs';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useAxios from "axios-hooks";
import { isEmpty, isObject, omit, pick } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import InputController from "components/Form/InputController";
import {
  ValidateMessage,
  ROOT_API,
  API_ROUTES,
  PostTypeOption,
  FileImageValid,
  MAX_VIDEO_UPLOAD,
  PostType,
} from "utils/constant";
import SelectController from "components/Form/SelectController";
import EditorController from "components/Form/EditorController";
import { mappingMiniLeagueOption } from "utils/mapping";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { FILE_TOKEN } from "utils/constant";
import { STORE_API } from "utils/constant";
import { STORE_LOAD_API } from "utils/constant";
import { uploadPhotoPost, useChangeStatusPostMutation, useCreatePostMutation, useQueryGetDetailPost, useUpdatePostMutation } from "services/post";
import { useDropzone } from 'react-dropzone';
const CreatePostDialog = ({ seasonDetail, onClose }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const inputimageFileRef = useRef();
  const history = useHistory();
  const inputVideoUrlRef = useRef();
  const cancelRef = useRef();
  const toast = useToast();
  const params = useParams();
  const { id } = params || {};
  const [leagueOption, setLeagueOption] = useState([]);
  const [fileSelected, setFileSelected] = useState({
    imageFile: null,
    videoUrl: null,
  });
  const [isDragEnter, setIsDragEnter] = useState(false);
  const [files, setFiles] = useState(null);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDragEnter: () => {
      setIsDragEnter(true);
    },
    onDragLeave: () => {
      setIsDragEnter(false);
    },
    onDrop: acceptedFiles => {
      setIsDragEnter(false);
      setError({ imageFile: null });
      const file = acceptedFiles[0]
      if (file) {
        const newFile = {
          id: nanoid(),
          preview: URL.createObjectURL(file),
          file,
        };
        setFiles(newFile);
      }
    },
  });
  const [error, setError] = useState({
    imageFile: null,
    videoUrl: null,
  });
  const [isPublic, setIsPublic] = useState(false);
  // const [{ loading: createLoading }, createPostApi] = useAxios(
  //   {
  //     method: "post",
  //     url: ROOT_API + API_ROUTES.CREATE_POST,
  //   },
  //   { manual: true }
  // );
  // const [{ loading: updateLoading }, updatePostApi] = useAxios(
  //   {
  //     method: "post",
  //     url: ROOT_API + API_ROUTES.UPDATE_POST,
  //   },
  //   { manual: true }
  // );
  const createPostMutation = useCreatePostMutation();
  const updatePostMutation = useUpdatePostMutation();
  const changeStatusPost = useChangeStatusPostMutation();
  const { data: postDetailData, refetch } = useQueryGetDetailPost(id, {
    enabled: !!id,
  })
  const [{ loading: uploadLoading }, uploadVideoApi] = useAxios(
    {
      method: "post",
      url: STORE_API + API_ROUTES.UPLOAD_FILE_STORE,
      headers: {
        fToken: FILE_TOKEN,
      },
    },
    { manual: true }
  );
  const [, publicPostApi] = useAxios(
    {
      method: "post",
      url: `${ROOT_API}${API_ROUTES.PUBLIC_POST}/${id}`,
    },
    { manual: true }
  );
  // const [{ loading: loadingPostDetail }, getPostDetailApi] = useAxios(
  //   {
  //     url: `${ROOT_API}${API_ROUTES.POSTS}/${id}`,
  //   },
  //   { manual: true }
  // );
  const [{ data: leagueData }] = useAxios(
    {
      url: ROOT_API + API_ROUTES.LEAGUES_FILTER,
      params: { isActive: true },
    },
    {
      useCache: false,
    }
  );

  // useEffect(
  //   () => () => {
  //     reset();
  //   },
  //   []
  // );

  useEffect(() => {
    const postDetail = postDetailData?.data;
    if (!isEmpty(postDetail)) {
      reset({
        title: postDetail.title,
        body: postDetail.body,
        tags: postDetail?.tags,
        // description: postDetail?.description,
        type: PostTypeOption.find((item) => item.value === postDetail?.type),
      });
      setFiles(
        {
          id: nanoid(),
          preview: ROOT_API + postDetail?.thumbnailUrl,
          path: postDetail?.thumbnailUrl,
        }
      );
      setIsPublic(postDetail?.isShow);
    }
  }, [postDetailData, PostTypeOption]);
  // useEffect(() => {
  //   if (!!id) {
  //     reset({
  //       ...pick(detailPost, ["title", "tags", "body", "videoUrl"]),
  //       type: PostTypeOption.find((item) => item.value === detailPost?.type),
  //       leagueId: leagueOption?.find(
  //         (item) => item?.value === detailPost?.leagueId
  //       ),
  //       description: detailPost?.seo?.description,
  //     });
  //     setFileSelected({
  //       imageFile: {
  //         filePreview: detailPost?.thumbnailUrl
  //           ? process.env.REACT_APP_API_HOST + detailPost?.thumbnailUrl
  //           : null,
  //       },
  //     });
  //     setIsPublic(detailPost?.isShow);
  //   }
  // }, [id, leagueOption]);

  useEffect(() => {
    setLeagueOption(mappingMiniLeagueOption(leagueData?.data));
  }, [leagueData]);

  const schema = yup.object().shape({
    title: yup.string().required(ValidateMessage.required),
    type: yup.object().nullable().required(ValidateMessage.required),
    videoUrl: yup
      .string()
      .nullable()
      .when("type", {
        is: (type) => (type?.value === PostType.Highlight ? true : false),
        then: () => yup.string().required(ValidateMessage.required),
      }),
    body: yup.string().required(ValidateMessage.required),
    tags: yup.string().required(ValidateMessage.required),
    // slug: yup.string().required(ValidateMessage.required),
    // leagueId: yup.object().nullable().required(ValidateMessage.required),
  });
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: "",
      videoUrl: "",
      body: "",
      tags: "",
      type: undefined,
      // leagueId: undefined,
      // description: "",
      // slug: "",
    },
  });
  const watchType = watch("type");

  const handleSuccess = () => {
    toast({
      title: `${id ? "Edit" : "Create"} Post Successfully`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    // !id && history.goBack();
  };
  const handleError = (error) => {
    toast({
      title:
        error ||
        `${id ? "Edit" : "Create"} Post Fail`,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  const onUploadPhoto = async () => {
    const formData = new FormData();
    const filesUpload = files.file
    let pathFiles
    if (!isEmpty(filesUpload)) {
      formData.append('imageFile', filesUpload);
      const response = await uploadPhotoPost(formData);
      pathFiles = response?.data?.imageUrl;
    }
    return pathFiles;
  };

  const onSubmit = async (dataForm) => {
    // return
    // if (!fileSelected.imageFile?.filePreview) {
    //   setError({
    //     imageFile: "The field is not empty ",
    //   });
    //   return;
    // }
    if (isEmpty(files)) {
      setError({ imageFile: 'Tải lên ít nhất 1 hình ảnh' });
      return;
    }

    const photosPath = await onUploadPhoto();
    const dataSubmit = {
      ...dataForm,
      type: dataForm.type?.value,
      title: dataForm.title,
      body: dataForm.body,
      tags: dataForm.tags,
      // description: dataForm.description,
      thumbnailUrl: photosPath,
    };
    // const formData = new FormData();
    // formData.append("type", dataForm.type?.value);
    // formData.append("title", dataForm.title);
    // formData.append("body", dataForm.body);
    // formData.append("tags", dataForm.tags);
    // formData.append("description", dataForm.description);
    // if (!!fileSelected.imageFile?.file) {
    //   formData.append("imageFile", fileSelected?.imageFile?.file);
    // }
    if (!!id) {
      updatePostMutation.mutate(
        { ...dataSubmit, id }, {
        onSuccess: () => {
          refetch?.()
          handleSuccess();
          history.push('/admin/posts');
        },
        onError: (error) => {
          handleError(error);
        }
      }
      );
    } else {
      createPostMutation.mutate(
        dataSubmit, {
        onSuccess: () => {
          handleSuccess();
          history.push('/admin/posts');
        },
        onError: (error) => {
          handleError(error);
        }
      })
    }
  };

  const handleFileVideo = (file, fieldName) => {
    if (file.size > MAX_VIDEO_UPLOAD) {
      handleErrorFile(fieldName, "Maximum video upload is 200Mb");
      return;
    }

    const formData = new FormData();

    formData.append("video", file);
    uploadVideoApi({ data: formData })
      .then((res) => {
        setValue("videoUrl", STORE_LOAD_API + res?.data?.response?.videoPath);
        clearErrors("videoUrl");
      })
      .catch((error) => {
        toast({
          title: "Upload video fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const onRemove = index => {
    setFiles(null);
  };


  const handleFileSelect = (fieldName, e) => {
    if (e?.target?.files?.[0]) {
      const fileSelected = e.target.files[0];
      const filePreview = URL.createObjectURL(fileSelected);
      const extensionFile = fileSelected?.name?.split(".")?.pop();

      if (fieldName === "videoUrl" && fileSelected.type === "video/mp4") {
        handleFileVideo(fileSelected, fieldName);
        return;
      }

      if (FileImageValid.includes(extensionFile)) {
        setFileSelected((prev) => ({
          ...prev,
          [fieldName]: { file: fileSelected, filePreview },
        }));

        return;
      }

      setFileSelected((prev) => ({
        ...prev,
        [fieldName]: null,
      }));
      handleErrorFile(
        fieldName,
        "File reload formats are supported only .png, .jpeg, .jpg"
      );
    }
  };

  const handleErrorFile = (fieldName, value) => {
    setError((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handlePublicPost = (status) => {
    changeStatusPost.mutate(id, {
      onSuccess: () => {
        if (postDetailData?.data?.isShow) {
          handleSuccess("UnPublic Post Successfully");
        } else {
          handleSuccess("Public Post Successfully");
        }
        // setIsPublic(status);
        refetch();
      },
      onError: () => {
        handleError("Public Post Fail");
      }
    })
    // publicPostApi({ data: undefined })
    //   .then(() => {
    //     toast({
    //       title: "Public Post Successfully",
    //       status: "success",
    //       duration: 9000,
    //       isClosable: true,
    //     });
    //   })
    //   .catch((error) => {
    //     toast({
    //       title:
    //         error?.response?.data?.errors?.[0]?.msg ||
    //         error?.response?.data?.msg ||
    //         "Public Post Fail",
    //       status: "error",
    //       duration: 9000,
    //       isClosable: true,
    //     });
    //   });
    // setIsPublic(status);
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card bg={'#fff'} overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Flex justifyContent="space-between">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              {`${id ? "Cập nhật" : "Tạo"} bài viết`}
            </Text>
            <Switch
              size="md"
              isChecked={postDetailData?.data?.isShow}
              onChange={(e) => handlePublicPost(e.target.checked)}
            />
          </Flex>
        </CardHeader>
        <CardBody pb={4}>
          <form>
            <SelectController
              control={control}
              name="type"
              label="Type"
              isRequired
              options={PostTypeOption}
            />
            {/* <FormControl pt={4} isRequired>
              <FormLabel minW="150px">Thumbnail</FormLabel>
              <Box alignItems="center">
                <Flex alignItems="center">
                  <input
                    type="file"
                    hidden
                    ref={inputimageFileRef}
                    onChange={(e) => {
                      handleFileSelect("imageFile", e);
                    }}
                  />
                  {!!fileSelected.imageFile?.filePreview ? (
                    <Flex direction="column" alignItems="center" w="full">
                      <Image
                        boxSize="full"
                        height="300px"
                        objectFit="cover"
                        borderRadius="6px"
                        src={fileSelected.imageFile?.filePreview}
                      />
                      <Text
                        ml={3}
                        fontSize="14px"
                        color="blue.400"
                        cursor="pointer"
                        onClick={() => {
                          handleErrorFile("imageFile", null);
                          inputimageFileRef?.current?.click();
                        }}
                      >
                        Change
                      </Text>
                    </Flex>
                  ) : (
                    <Button
                      variant="primary"
                      maxH="30px"
                      onClick={() => {
                        handleErrorFile("imageFile", null);
                        inputimageFileRef?.current?.click();
                      }}
                    >
                      Choose file
                    </Button>
                  )}
                </Flex>
                {!!error.imageFile && (
                  <Text pt={1} color={"red.500"} fontSize="13px">
                    {error.imageFile}
                  </Text>
                )}
              </Box>
            </FormControl> */}
            <FormControl pt={4}>
              <FormLabel minW="150px">Ảnh</FormLabel>
              <Box
                {...getRootProps()}
                cursor="pointer"
                borderWidth="1px"
                borderStyle="dashed"
                borderRadius="12px"
                padding="55px"
                textAlign="center"
                height="210px"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <input {...getInputProps()} />
                <BsUpload size={40} />
                {isDragEnter ? (
                  <p>Thả ảnh của bạn</p>
                ) : (
                  <>
                    <p>
                      <Text as="span" color="red.400">
                        Tải tập tin lên
                      </Text>
                      &nbsp;hoặc kéo và thả
                    </p>
                  </>
                )}
              </Box>
              <Grid templateColumns="repeat(5, 1fr)" gap={6} pt={5}>
                {files &&
                  <GridItem w={'100%'} key={files?.id} position="relative">
                    <Image src={files?.preview} w="full" h="full" objectFit="contain" />
                    <IconButton bg="transparent" position="absolute" top="0" right="0" onClick={() => onRemove()}>
                      <BsXCircle size={16} color="red" />
                    </IconButton>
                  </GridItem>
                }
              </Grid>
              {!!error.imageFile && (
                <Text pt={1} color={'red.500'} fontSize="13px">
                  {error.imageFile}
                </Text>
              )}
            </FormControl>
            <FormControl
              pt={4}
              isRequired={watchType?.value === PostType.Highlight}
            >
              <FormLabel minW="150px">Video</FormLabel>
              <Flex justifyContent="center" alignItems="center">
                <InputController control={control} name="videoUrl" isRequired />
                <Box ml={3} alignItems="center">
                  <Flex alignItems="center">
                    <input
                      type="file"
                      hidden
                      ref={inputVideoUrlRef}
                      accept="video/mp4"
                      onChange={(e) => {
                        handleFileSelect("videoUrl", e);
                      }}
                    />
                    <Button
                      variant="primary"
                      maxH="30px"
                      isLoading={uploadLoading}
                      onClick={() => {
                        handleErrorFile("videoUrl", null);
                        inputVideoUrlRef?.current?.click();
                      }}
                    >
                      Choose file
                    </Button>
                  </Flex>
                </Box>
              </Flex>
              {!!error.videoUrl && (
                <Text pt={1} color={"red.500"} fontSize="13px">
                  {error.videoUrl}
                </Text>
              )}
            </FormControl>
            {/* <InputController
              control={control}
              name="videoUrl"
              label="Link Video"
              // isRequired
              styleContainer={{ pt: "4" }}
            /> */}
            <InputController
              control={control}
              name="title"
              label="Title"
              isRequired
              styleContainer={{ pt: "4" }}
            />
            {/* <InputController
              control={control}
              name="slug"
              label="Slug"
              isRequired
              styleContainer={{ pt: "4" }}
            /> */}

            <EditorController
              styleContainer={{ pt: "4" }}
              control={control}
              name="body"
              label="Body"
              isRequired
              uploadApiUrl={API_ROUTES.UPLOAD_IMAGE}
            />
            <InputController
              control={control}
              name="tags"
              label="Tags"
              isRequired
              styleContainer={{ pt: "4" }}
              disabled={seasonDetail}
            />
            {/* <SelectController
              styleContainer={{ pt: "4" }}
              control={control}
              isRequired
              name="leagueId"
              label="League"
              options={leagueOption}
              menuPlacement="top"
            /> */}
          </form>
          <Flex pt={4} alignItems="flex-end" justifyContent="flex-end">
            <Button
              ref={cancelRef}
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              isLoading={createPostMutation.loading || updatePostMutation.loading}
              onClick={handleSubmit(onSubmit)}
            >
              OK
            </Button>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default CreatePostDialog;
