import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Text,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useQueryGetListCategory } from 'services/category';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { ModalType } from 'constants/common';
import SizeTable from './components/Table';
import Pagination from 'components/Pagination/Pagination';
import { useQueryGetListBet } from 'services/bet';

function BookMaket() {
    const textColor = useColorModeValue('gray.700', 'white');
    const history = useHistory();
    const [sizeEditing, setSizeEditing] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [filter, setFilter] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    useEffect(() => {
        if (!CookieStorage.isAuthenticated()) {
            return history.push('/auth/sign-in');
        }
    }, []);

    const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();
    const { isOpen: isChangeStatusModalOpen, onOpen: onOpenChangeStatusModal, onClose: onCloseChangeStatusModal } = useDisclosure();
    const openModal = useMemo(
        () => ({
            [ModalType.Add]: onOpenCreateModal,
            [ModalType.ChangeStatus]: onOpenChangeStatusModal,
        }),
        [onOpenCreateModal, onOpenChangeStatusModal]
    );
    const closeModal = useMemo(
        () => ({
            [ModalType.Add]: onCloseCreateModal,
            [ModalType.ChangeStatus]: onCloseChangeStatusModal,
        }),
        [onCloseCreateModal, onCloseChangeStatusModal]
    );

    const { data: betData, refetch } = useQueryGetListBet(
        { ...filter },
        { enabled: CookieStorage.isAuthenticated() }
    );
    const handleUpdateItem = (size, modalType) => {
        openModal?.[modalType]?.();
        setSizeEditing(size);
    };

    const handleSearch = () => {
        setFilter({
            ...filter,
            searchKeyword: searchKeyword,
        });
    };


    return (
        <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
            <Card p="16px" mb="24px" bg="#fff">
                <CardHeader p="12px 5px" mb="12px">
                    <Flex justifyContent={'space-between'}>
                        <Flex direction={'column'}>
                            <Flex direction="column" gap={'30px'}>
                                <Text fontSize="xl" color={textColor} fontWeight="bold">
                                    Danh sách nhà cái
                                </Text>
                            </Flex>
                            <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                                <Stack>
                                    <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                                        <FormControl minWidth={{ base: 'full', sm: '300px' }}>
                                            <FormLabel>Tìm kiếm</FormLabel>
                                            <Input value={searchKeyword} onChange={e => setSearchKeyword(e.target.value)} />
                                        </FormControl>
                                        <Button variant="primary" maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                                            <Text fontSize="md" fontWeight="bold" cursor="pointer">
                                                Tìm kiếm
                                            </Text>
                                        </Button>
                                    </Flex>
                                </Stack>
                            </Flex>
                        </Flex>
                        {/* 
                        <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={onOpenCreateModal}>
                            <Text fontSize="md" fontWeight="bold" cursor="pointer">
                                Thêm
                            </Text>
                        </Button> */}

                    </Flex>
                </CardHeader>
                <CardBody overflowX="auto">
                    <Stack overflow={'auto'}>
                        <SizeTable betData={betData?.data || []} handleUpdateCategory={handleUpdateItem} refetch={refetch} />
                    </Stack>
                    <Flex justifyContent={'flex-end'}>
                        <Pagination
                            page={betData?.pagination?.page}
                            pageLength={betData?.pagination?.pageSize}
                            totalRecords={betData?.pagination?.count}
                            onPageChange={(page, pageLength) => {
                                setFilter({
                                    ...filter,
                                    pageSize: pageLength,
                                    pageIndex: page - 1,
                                });
                            }}
                        />
                    </Flex>
                </CardBody>
            </Card>
        </Flex>
    );
}

export default BookMaket;
