import { Box, Flex, IconButton, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { FaHistory } from 'react-icons/fa';
import { TbLockSquare } from "react-icons/tb";
import isEmpty from 'lodash/isEmpty';
import { ModalType } from 'constants/common';
import { formatDate } from 'utils/helpers';
import { useHistory } from 'react-router-dom';

const SizeTable = ({ categorysData, handleUpdateCategory, refetch }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const history = useHistory();

  const handleRowClick = (ticket, type) => {
    handleUpdateCategory(ticket, type);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('username', {
        header: 'Tên',
        cell: info => info.getValue(),
      }),

      columnHelper.accessor('email', {
        header: 'Email',
        cell: info => info.getValue(),
      }),

      columnHelper.accessor('gender', {
        header: 'Giới tính',
        cell: info => info.getValue(),
      }),

      columnHelper.accessor('balance', {
        header: 'Số dư',
        cell: info => info.getValue(),
      }),

      columnHelper.accessor('feeSubcribe', {
        header: 'Phí đăng kí theo dõi',
        cell: info => info.getValue(),
      }),

      // columnHelper.accessor('feeSubcribe', {
      //   header: 'Tổng tip',
      //   cell: info => info.getValue(),
      // }),

      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => <Text whiteSpace={'nowrap'}>{formatDate(info.row.original.createdAt, 'DD.MM.yyyy HH:mm')}</Text>,
      }),

      columnHelper.accessor('status', {
        header: 'Trạng thái',
        cell: info => {
          const status = info.getValue();
          const displayStatus = status === 'Temporary' ? 'Khoá tạm thời' : status === 'Normal' ? 'Bình thường' : 'Khoá vĩnh viễn';
          return displayStatus;
        },
      }),

      columnHelper.accessor('action', {
        header: '',
        cell: info => {
          return (
            <Flex alignItems="center" gap={2}>
              <IconButton
                bg="transparent"
                onClick={() => {
                  handleRowClick(info.row.original, ModalType.ChangeStatus)
                }}
              >
                <TbLockSquare cursor="pointer" size={22} />
              </IconButton>
              <Tooltip hasArrow top label="History match review" bg="gray.300" color="black">
                <IconButton
                  bg="transparent"
                  onClick={() => {
                    history.push(`/admin/reviewer/history-match-review/${info.row.original._id}`)
                  }}
                >
                  <FaHistory cursor="pointer" size={18} />
                </IconButton>
              </Tooltip>
            </Flex>
          )
        },
      }),
    ],
    [categorysData]
  );

  const table = useReactTable({
    data: categorysData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={6}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
