// Chakra imports
import {
    Flex,
    Table,
    Button,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useDisclosure,
    Input,
    InputRightElement,
    InputGroup,
    useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useRef, useState } from "react";
import { TablePagination } from "@trendmicro/react-paginations";
import { initialFilter } from "utils/constant";
import { SearchIcon } from "@chakra-ui/icons";
import Loading from "components/Layout/Loading";
import { useQueryGetListpost } from "services/category";
import { CookieStorage } from "utils/cookie-storage";
import SeasonTableRow from "./components/SeasonTableRow";
import { useHistory } from "react-router-dom";
import { useQueryGetPost } from "services/post";

function Post() {
    const history = useHistory();
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const isLoggedIn = CookieStorage.isAuthenticated();
    const [filter, setFilter] = useState(initialFilter);
    const toast = useToast();
    const [searchValue, setSearchValue] = useState("");
    const [loadingImport, setLoadingImport] = useState(false);
    const {
        isOpen: isOpenCreate,
        onOpen: onOpenCreate,
        onClose: onCloseCreate,
    } = useDisclosure();
    const [loading, setLoading] = useState(false)
    const refSearchButton = useRef(null);

    const { data: post, isLoading, refetch } = useQueryGetPost({ ...filter }, { enabled: isLoggedIn })
    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Card overflowX={{ sm: "scroll", xl: "hidden" }} bgColor={'#fff'} pb="0px">
                <CardHeader p="6px 0px 22px 0px">
                    <Flex justifyContent={'space-between'}>
                        <Text fontSize="xl" color={textColor} fontWeight="bold">
                            Danh sách bài viết
                        </Text>
                        <Flex gap={2} alignItems={"center"} alignSelf={"start"}>
                            <Button
                                bg="#3182ce"
                                color="#fff"
                                _hover={{ bg: '#67a1d7' }}
                                onClick={() => {
                                    history.push('/admin/posts/create');
                                }}
                            >
                                <Text fontSize="md" fontWeight="bold" cursor="pointer">
                                    Thêm
                                </Text>
                            </Button>
                        </Flex>
                    </Flex>
                </CardHeader>
                <CardBody pb={4}>
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <>
                            {/* <Flex mb={3} columnGap={2} justifyContent={'space-between'}>
                                <Button
                                    colorScheme="blue"
                                    onClick={() => {
                                        history.push("/admin/posts/create");
                                    }}
                                >
                                    Create Post
                                </Button>
                            </Flex> */}
                            <Flex columnGap={3} mb={3}>
                                <InputGroup>
                                    <InputRightElement pointerEvents="none">
                                        <SearchIcon color="gray.300" />
                                    </InputRightElement>
                                    <Input
                                        type="text"
                                        variant="outline"
                                        placeholder="Tìm kiểm với tên, mã số"
                                        value={searchValue}
                                        onChange={(event) => setSearchValue(event.target.value)}
                                        onKeyUp={(event) => {
                                            if (event.key === "Enter" && event?.target?.value) {
                                                const searchBtn = refSearchButton?.current;
                                                if (searchBtn !== null) {
                                                    searchBtn.click();
                                                }
                                            }
                                        }}
                                    />
                                </InputGroup>

                                <Button
                                    ref={refSearchButton}
                                    disabled={!searchValue}
                                    colorScheme="blue"
                                    onClick={() => {
                                        setFilter({
                                            ...filter,
                                            searchKeyword: searchValue,
                                            pageIndex: 0,
                                        });
                                    }}
                                >
                                    Tìm kiếm
                                </Button>
                                <Button
                                    onClick={() => {
                                        setSearchValue("");
                                        setFilter({
                                            ...filter,
                                            searchKeyword: "",
                                            pageIndex: 0,
                                        });
                                    }}
                                >
                                    Đặt lại
                                </Button>
                            </Flex>
                            <Table variant="simple" color={textColor}>
                                <Thead>
                                    <Tr my=".8rem" pl="0px" color="gray.400">
                                        <Th borderColor={borderColor} color="gray.400">
                                            Tiêu đề
                                        </Th>
                                        <Th borderColor={borderColor} color="gray.400">
                                            Thể loại
                                        </Th>
                                        <Th borderColor={borderColor} color="gray.400">
                                            trạng thái
                                        </Th>
                                        <Th borderColor={borderColor} color="gray.400">
                                            hành động
                                        </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {post?.data?.length
                                        ? post?.data?.map((row, index, arr) => {
                                            return (
                                                <SeasonTableRow
                                                    row={row}
                                                    isLast={index === arr.length - 1 ? true : false}
                                                    key={index}
                                                    refetchData={refetch}
                                                />
                                            );
                                        })
                                        : ""}
                                </Tbody>
                            </Table>
                            <Flex justifyContent={"flex-end"}>
                                <TablePagination
                                    type="full"
                                    page={post?.pagination?.page}
                                    pageLength={post?.pagination?.pageSize}
                                    totalRecords={post?.pagination?.count}
                                    onPageChange={({ page, pageLength }) => {
                                        setFilter({
                                            ...filter,
                                            pageSize: pageLength,
                                            pageIndex: page - 1,
                                        });
                                    }}
                                    prevPageRenderer={() => <i className="fa fa-angle-left" />}
                                    nextPageRenderer={() => <i className="fa fa-angle-right" />}
                                />
                            </Flex>
                        </>
                    )}
                </CardBody>
            </Card>
            {/* <CreateLeagueDialog
                isOpen={isOpenCreate}
                onOpen={onOpenCreate}
                onClose={onCloseCreate}
            /> */}
        </Flex>
    );
}

export default Post;
