import { Box, FormControl, Switch, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { formatDate } from 'utils/helpers';

const SizeTable = ({ categorysData, handleUpdateCategory, refetch }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();

  const handleSwitchChange = row => {
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('match', {
        header: 'Id',
        cell: info => info.getValue().matchId,
      }),

      columnHelper.accessor('match', {
        header: 'Thông tin trận đấu',
        cell: info => {
          return (
            <Box flexDirection={'column'} minWidth={'200px'}>
              <Text mb={2}>
                {info.getValue().home_name} - {info.getValue().away_name} ({info.getValue().homeGoals}{' '}
                {info.getValue().homeGoals && info.getValue().awayGoals && '-'} {info.getValue().awayGoals})
              </Text>
              <Text mb={2}>{info.getValue().league_name}</Text>
              <Text>Thời gian: {formatDate(info.getValue().time, 'hA MMM Do YYYY')}</Text>
            </Box>
          );
        },
      }),

      columnHelper.accessor('match', {
        header: 'Tổng tip',
        cell: info => info.getValue().totalTip,
      }),

      columnHelper.accessor('content', {
        header: 'Nội dung',
        cell: info => (
          <Box minWidth={'200px'}>
            <Text mb={2}>{info.getValue()}</Text>
          </Box>
        ),
      }),

      columnHelper.accessor('fee', {
        header: 'Phí',
        cell: info => info.getValue(),
      }),

      columnHelper.accessor('tip', {
        header: 'Tip',
        cell: info => info.getValue(),
      }),

      columnHelper.accessor('isPublish', {
        header: 'Xuất bản',
        cell: info => (
          <FormControl display="flex" alignItems="center">
            <Switch isChecked={info.getValue()} onChange={() => handleSwitchChange(info.row.original)} />
          </FormControl>
        ),
      }),

      columnHelper.accessor('isFree', {
        header: 'Miễn phí',
        cell: info => (
          <FormControl display="flex" alignItems="center">
            <Switch isChecked={info.getValue()} onChange={() => handleSwitchChange(info.row.original)} />
          </FormControl>
        ),
      }),

      columnHelper.accessor('isFollow', {
        header: 'Theo dõi',
        cell: info => (
          <FormControl display="flex" alignItems="center">
            <Switch isChecked={info.getValue()} onChange={() => handleSwitchChange(info.row.original)} />
          </FormControl>
        ),
      }),
    ],
    [categorysData]
  );

  const table = useReactTable({
    data: categorysData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={6}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
